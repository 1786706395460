// https://swiperjs.com/react
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Autoplay, Navigation } from 'swiper'
import { useViewport } from '../hooks/useViewport'
import 'swiper/css'
import 'swiper/css/navigation';
import { Item, Items } from './Items'

type SliderProps = {
  items : Items, 
  selectItemHandler : (item : Item)=>void
}

const Slider = (props : SliderProps) => {
  const [windowDimensions] = useViewport()
  const { width } = windowDimensions
  // install Swiper components
  SwiperCore.use([Navigation, Autoplay])

  return (
    <div style={{marginTop:40}}>
      <Swiper
        autoplay={{ delay: 1200 }}
        spaceBetween={30} 
        loop={true}
        slidesPerView={width >= 1378 ? 8 : width >= 998 ? 6 : width >= 625 ? 4 : 2}
        loopAdditionalSlides={
          width >= 1378 ? 4 : width >= 998 ? 3 : width >= 625 ? 2 : 2
        }

      >
        {props.items &&
          props.items.map((item, idx) => {
            let itemImageUrl = item.logo
              return (
                <SwiperSlide
                  onClick={() => props.selectItemHandler(item)}
                  key={idx}
                  className={
                    'itemShowcase__container--item' 
                  }
                >
                  <img
                    src={itemImageUrl}
                    alt={item.firma}
                    height={100}
                    className='itemShowcase__container--item-image'
                  />
                </SwiperSlide>
              )
            }
          )}
      </Swiper>
    </div>
  )
}

export default Slider
