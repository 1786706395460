import React from 'react';
import ReactDOM from 'react-dom';
import AppSlider from './AppSlider';
import Szukaj from './components/Szukaj';
import './style.css';

ReactDOM.render(
    <AppSlider />,
  document.getElementById('r_slider')
);

ReactDOM.render(
  <Szukaj />,
document.getElementById('r_szukaj')
);

