import React, { FunctionComponent, useState } from 'react';
import { Modal } from './modal/modal';
import Oferty from './Oferty';
import json_data from '../json/data.json';
import { Item, Items } from './Items';
import { YesButton } from './modal/modal.style';

const useModal = () => {
  const [isShown, setIsShown] = useState<boolean>(false);
  const toggle = () => setIsShown(!isShown);
  return {
    isShown,
    toggle,
  };
};

const Szukaj: FunctionComponent = () => {
  const { isShown, toggle } = useModal();
  const [value, setValue] = useState('');

  const onCancel = () => toggle();
  const onChange = (e: React.FormEvent<HTMLInputElement>) => {
    setValue(e.currentTarget.value);
  };

  let items : Items = []
  if (json_data) {
    for (let cat of json_data) {
      items=[...items, ...cat.items]
    }
  }

  return (
    <React.Fragment>
      <div style={{width:'80%',float:'left'}}>
      <input type="text"  value={value} onChange={onChange} />
      <button onClick={toggle} className="button" role="button" 
      value='Szukaj'
          >Szukaj</button>

      </div>
      <div style={{width:'20%',float:'left'}}>
      </div>
      <div style={{clear:'both'}}></div>
      <Modal
        isShown={isShown}
        hide={toggle}
        headerText="Znalezione oferty"
        modalContent={
          <div>
            Filtr:&nbsp;
            <input value={value} onChange={onChange}></input>  
            <YesButton onClick={ onCancel }>Zamknij</YesButton>
           <hr />
            <Oferty   
               items={items} 
               selectItemHandler ={ (item : Item)=>{ alert(JSON.stringify(item))} }/>
          </div>
        }
      />
    </React.Fragment>
  );
};


export default Szukaj;
