import React from 'react';
import Slider from './components/Slider'
import json_data from './json/data.json';
import { Item, Items } from './components/Items'

function AppSlider() {
  let items : Items = []
  if (json_data) {
    for (let cat of json_data) {
      items=[...items, ...cat.items]
    }
  }

  return ( <Slider items={items} selectItemHandler ={ (item : Item)=>{ alert(JSON.stringify(item))} } />
  );
}
export default AppSlider;
