import { Component } from 'react'

import { Item, Items } from './Items'

type OfertyProps = {
  items : Items, 
  selectItemHandler : (item : Item)=>void 
}


class Oferty extends Component<OfertyProps> {
//const Oferty = (props : OfertyProps) => {
 
 render() {
  return (
    <>
      <div style={{
      textAlign:"center",
       width:"100%"}}>
      <div
      style={{ marginLeft: "auto", marginRight: "auto" }}
        >
        {this.props.items &&
          this.props.items.map((item, idx) => {
            let itemImageUrl = item.logo;
            return (
              <div style={{ backgroundColor: '#0000ff'}}>
                 <div style={{float:"left", width:320, maxWidth:320}}>
                  <button>
                  <img
                    src={itemImageUrl}
                    className='itemShowcase__container--item-image'
                    style={{
                    height:260
                    }}
                    alt={item.firma}
                  />
                  <div style={{height:"4em"}}>
                    <span style={{fontSize:"1em"}}>
                      {item.firma}
                    </span>
                    </div>
                  </button>
                  <div style={{ width:"100%", padding: "auto", textAlign: "center" }}
                  >
                  <button 
                    onClick={() => this.props.selectItemHandler(item)}>
                    wybierz
                  </button>
                  </div>
                  </div>
              </div>
              )
            }
          )}
          <div style={{clear:"both"}}></div>
      </div>
      </div>
    </>
    )
  }
}

export default Oferty
